<!--
 * @Descripttion: 选择日志接收人
 * @version: 1.0.0
 * @Author: liujx@imyfone.cn
 * @Date: 2022-01-26 09:24:03
 * @LastEditors: liujx@imyfone.cn
 * @LastEditTime: 2025-02-10 15:17:41
-->
<template>
  <div class="transfer-wrapper">
    <el-scrollbar class="transfer-left border-6">
      <div class="search-box">
        <el-select
          v-model="filterText"
          filterable
          remote
          reserve-keyword
          placeholder="请输入用户名"
          :loading="loading"
          :remote-method="getUserByName"
          v-inputMax="20"
        >
          <el-option
            v-for="item in options"
            :key="item.ding_user_id"
            :label="item.name"
            :value="item.ding_user_id"
            @click="clickUser(item, 'search')"
          >
            <!-- <el-checkbox v-model="item.selected"  :disabled="item.disabled"></el-checkbox> -->
            <div class="user-info">
              <UserAvatar :isPreview="true" :user="item" size="24" fontSize="10" medalSize="36"></UserAvatar>
              <span class="del-blue">{{ item.name }}</span>
            </div>
          </el-option>
        </el-select>
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M15 9A6 6 0 1 1 3 9a6 6 0 0 1 12 0zm-1.015 6.258a8 8 0 1 1 1.273-1.273l3.796 3.796a.9.9 0 0 1-1.273 1.273l-3.796-3.796z" fill="#333"/></svg>
      </div>
      <el-breadcrumb separator=">">
        <el-breadcrumb-item el-breadcrumb-item v-for="item in leftBread" :key="item.dept_id" @click="getTeam(item)">{{ item.name }}</el-breadcrumb-item>
      </el-breadcrumb>
      <ul class="user-list">
        <li v-if="leftData.length > 0">
          <el-checkbox v-model="checkAll" @change="selectAll($event, leftData)"></el-checkbox>
          {{`全选 (${allCount})`}}
        </li>
        <li v-for="item in leftData" :key="item.name">
          <el-checkbox v-model="item.selected" :disabled="item.disabled" @change="selectItem(item)"></el-checkbox>
          <template v-if="item.dept_id">
            <span @click="getTeam(item)" class="dep-item">
              <img src="@/assets/img/company.svg" />
              {{ `${item.name} (${item.dept_count})` }}
            </span>
          </template>
          <template v-else>
            <div class="user-info" @click="clickUser(item)">
              <UserAvatar :isPreview="true" :user="item" size="24" fontSize="10" medalSize="36"></UserAvatar>
              <span>{{ item.name }}</span>
            </div>
          </template>
        </li>
      </ul>
    </el-scrollbar>
    <el-scrollbar class="transfer-right border-6">
      <template v-if="removeAll && rightData.length > 0">
        <div class="clear-box" @click="clearAll">
          <span class="default-icon">
            <svg width="50" height="20" viewBox="0 0 50 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5 5.833h10v10c0 .46-.373.834-.833.834H5.833A.833.833 0 0 1 5 15.833v-10z" stroke="#333" stroke-width="1.667"/><rect x="2.917" y="5.417" width="14.167" height=".833" rx=".417" stroke="#333" stroke-width=".833"/><rect x="12.083" y="8.75" width="5" height=".833" rx=".417" transform="rotate(90 12.083 8.75)" stroke="#333" stroke-width=".833"/><rect x="8.75" y="8.75" width="5" height=".833" rx=".417" transform="rotate(90 8.75 8.75)" stroke="#333" stroke-width=".833"/><rect x="7.917" y="2.917" width="4.167" height=".833" rx=".417" stroke="#333" stroke-width=".833"/><path d="M25.924 4.457a14.442 14.442 0 0 1 1.963 1.664l-.884.884c-.468-.533-1.118-1.092-1.963-1.69l.884-.858zm-.403 3.107a14.583 14.583 0 0 1 2.106 1.898l-.884.871c-.494-.585-1.196-1.222-2.106-1.911l.884-.858zm1.027 3.666l1.092.416a39.971 39.971 0 0 1-1.69 4.524l-1.144-.507a39.184 39.184 0 0 0 1.742-4.433zm4.992-6.89h1.209v.741h3.341v1.014h-3.341v.624h2.847v.988h-2.847v.65h3.705v1.014h-8.58V8.357h3.666v-.65h-2.717v-.988h2.717v-.624h-3.25V5.081h3.25V4.34zm-1.404 8.32v.702h4.043v-.702h-4.043zm4.043-.936v-.741h-4.043v.741h4.043zm-4.043 2.561v2.054h-1.157V9.982h6.383v5.07c0 .806-.403 1.209-1.183 1.209H32.97l-.299-1.092 1.157.065c.234 0 .351-.156.351-.442v-.507h-4.043zm8.879-3.419h8.983v1.17h-3.887v2.704h4.979v1.183H37.923V14.74h4.94v-2.704h-3.848v-1.17zm5.772-3.562a17.478 17.478 0 0 1 3.692 2.21l-.702 1.053c-1.183-.975-2.392-1.742-3.64-2.301l.65-.962zm-2.86.078l.767.884c-1.027.91-2.288 1.664-3.783 2.262l-.533-1.118c1.43-.52 2.613-1.196 3.549-2.028zm1.768-3.146c.156.39.299.819.455 1.287h4.966v2.769h-1.261V6.667h-8.697v1.612h-1.261V5.523h4.966a9.262 9.262 0 0 0-.52-1.079l1.352-.208z" fill="#333"/></svg>
          </span>
          <span class="hover-icon">
            <svg width="50" height="20" viewBox="0 0 50 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5 5.833h10v10c0 .46-.373.834-.833.834H5.833A.833.833 0 0 1 5 15.833v-10z" stroke="#7972F0" stroke-width="1.667"/><rect x="2.917" y="5.417" width="14.167" height=".833" rx=".417" stroke="#7972F0" stroke-width=".833"/><rect x="12.083" y="8.75" width="5" height=".833" rx=".417" transform="rotate(90 12.083 8.75)" stroke="#7972F0" stroke-width=".833"/><rect x="8.75" y="8.75" width="5" height=".833" rx=".417" transform="rotate(90 8.75 8.75)" stroke="#7972F0" stroke-width=".833"/><rect x="7.917" y="2.917" width="4.167" height=".833" rx=".417" stroke="#7972F0" stroke-width=".833"/><path d="M25.924 4.457a14.442 14.442 0 0 1 1.963 1.664l-.884.884c-.468-.533-1.118-1.092-1.963-1.69l.884-.858zm-.403 3.107a14.583 14.583 0 0 1 2.106 1.898l-.884.871c-.494-.585-1.196-1.222-2.106-1.911l.884-.858zm1.027 3.666l1.092.416a39.971 39.971 0 0 1-1.69 4.524l-1.144-.507a39.184 39.184 0 0 0 1.742-4.433zm4.992-6.89h1.209v.741h3.341v1.014h-3.341v.624h2.847v.988h-2.847v.65h3.705v1.014h-8.58V8.357h3.666v-.65h-2.717v-.988h2.717v-.624h-3.25V5.081h3.25V4.34zm-1.404 8.32v.702h4.043v-.702h-4.043zm4.043-.936v-.741h-4.043v.741h4.043zm-4.043 2.561v2.054h-1.157V9.982h6.383v5.07c0 .806-.403 1.209-1.183 1.209H32.97l-.299-1.092 1.157.065c.234 0 .351-.156.351-.442v-.507h-4.043zm8.879-3.419h8.983v1.17h-3.887v2.704h4.979v1.183H37.923V14.74h4.94v-2.704h-3.848v-1.17zm5.772-3.562a17.478 17.478 0 0 1 3.692 2.21l-.702 1.053c-1.183-.975-2.392-1.742-3.64-2.301l.65-.962zm-2.86.078l.767.884c-1.027.91-2.288 1.664-3.783 2.262l-.533-1.118c1.43-.52 2.613-1.196 3.549-2.028zm1.768-3.146c.156.39.299.819.455 1.287h4.966v2.769h-1.261V6.667h-8.697v1.612h-1.261V5.523h4.966a9.262 9.262 0 0 0-.52-1.079l1.352-.208z" fill="#7972F0"/></svg>
          </span>
        </div>
      </template>
      <ul class="user-list">
        <li class="rigth-item" v-for="item in rightData" :key="item.name" :data-id="item.id">
          <span>
            <template  v-if="item.dept_id">
              <img src="@/assets/img/company.svg" />
              {{ `${item.name} (${item.dept_count})`}}
            </template>
            <template v-else>
              <UserAvatar :isPreview="true" :user="item" size="24" fontSize="10" medalSize="36"></UserAvatar>
              {{item.name}}
            </template>
          </span>
          <span @click="removeItem(item)" :class="{ disabled: item.disabled}">
            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="9.899" y="8.536" width="2" height="12" rx="1" transform="rotate(135 9.9 8.536)" fill="#333"/><rect x="1.414" y="9.95" width="2" height="12" rx="1" transform="rotate(-135 1.414 9.95)" fill="#333"/></svg>
          </span>
        </li>
      </ul>
    </el-scrollbar>
  </div>
</template>

<script>
import { reactive, toRefs, computed, onMounted, watch, nextTick } from 'vue'
import { getTeamList, getTeamUserList } from '@/apis/journal.js'
import { dialogMsg, confirm } from '@/utils/dialogMsg.js'

export default {

  props: {
    userIds: { // 已选择的用户列表
      type: Array,
      default: () => []
    },
    disableUserIds: { // 不可删除的用户列表
      type: Array,
      default: () => []
    },
    currentUser: {
      type: Object,
      default: () => {}
    },
    noMaxNum: { // 限制是否有最大选中人数
      type: Boolean,
      default: false
    },
    defaultAll: { // 是否默认全选
      type: Boolean,
      default: false
    },
    removeAll: { // 是否可以删除全部
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    onMounted(() => {
      getAllTeam()
    })

    watch(() => props.userIds, (newValue) => {
      if (newValue && state.userList) {
        initData()
      }
    })

    const state = reactive({
      leftBread: [], // 面包屑组织架构
      teamData: {
        children: [],
        user: [],
        dept_id: ''
      },
      leftData: [],
      rightData: [],
      rightIds: computed(() => state.rightData.map(item => item.dept_id || item.ding_user_id)),
      allCount: 0,
      checkAll: computed({
        set: (val) => state.leftData.forEach(item => {
          if (item.disabled) {
            item.selected = true
          } else {
            item.selected = val
          }
        }),
        get: () => (state.leftData.every(item => item.selected))
      }),
      filterText: '',
      userList: [], // 总用户列表
      options: [], // 搜索结果列表
      loading: false,
      defaultUserIds: computed(() => props.userIds),
      max: computed(() => !props.noMaxNum ? Number(process.env.VUE_APP_MAX) : 999999) // 可选择的最大人数
    })

    // 获取组织架构
    const getAllTeam = () => {
      state.leftBread = []
      const data = { type: 2 }
      getTeamList(data).then(res => {
        if (res.code === 0) {
          // 渲染面包屑导航
          initLeftBread(res.data)
          // 左侧渲染数据
          getLeftData(state.teamData, 'init')
          // 搜索用户列表
          getUserList(state.teamData.dept_id)
        }
      })
    }
    // 左边的数据
    const getLeftData = (data, type = 'click') => {
      state.leftData = []
      state.allCount = data.dept_count
      data.children && state.leftData.push(...data.children)
      data.user && state.leftData.push(...data.user)
      // 勾选左侧
      if (type === 'init') {
        state.leftData.forEach(item => {
          item.disabled = props.disableUserIds.includes(item.ding_user_id) // 不能被删除
          item.selected = state.defaultUserIds.includes(item.ding_user_id)
        })
      } else { // 点击切换
        state.leftData.forEach(item => {
          item.disabled = props.disableUserIds.includes(item.ding_user_id) // 不能被删除
          if (state.rightIds.includes(item.ding_user_id || item.dept_id)) {
            item.selected = true
          } else {
            item.selected = false
          }
        })
      }
    }

    // 点击整行，选择用户
    const clickUser = (user, type) => {
      if (user.dept_id || user.disabled) return
      if (type === 'search' && state.rightIds.includes(user.ding_user_id)) return
      user.selected = !user.selected
      selectItem(user, type)
    }
    // 勾选单个
    const selectItem = (obj, type = 'select') => {
      if (obj.selected) { // 去重添加
        // 判断选择是否超过限制
        const rigthCount = state.rightData.reduce((acc, cur) => acc + (cur.dept_count || 1), 0)
        if (rigthCount + (obj.dept_count || 1) > state.max) {
          obj.selected = false
          dialogMsg('error', `选择超过${state.max}人限制`)
          return
        }
        !state.rightIds.includes(obj.dept_id || obj.ding_user_id) && state.rightData.unshift(obj)
      } else { // 删除
        if (obj.dept_id) {
          state.rightData = state.rightData.filter(item => (item.dept_id !== obj.dept_id))
        } else {
          state.rightData = state.rightData.filter(item => (item.ding_user_id !== obj.ding_user_id))
        }
      }
      // 搜索添加
      if (type === 'search') {
        getUserByName()
        const user = state.leftData.filter(item => item.ding_user_id === obj.ding_user_id)
        user.length && (user[0].selected = true)
      }
    }
    // 全选
    const selectAll = (value, data) => {
      if (value) { // 全选
        // 判断选择是否超过限制
        const count = data.reduce((acc, cur) => acc + (cur.dept_count || 1), 0)
        if (count > state.max) {
          state.checkAll = false
          dialogMsg('error', `选择超过${state.max}人限制`)
          return
        }
        // 去重添加
        data.forEach(item => {
          if (item.selected && !state.rightIds.includes(item.dept_id || item.ding_user_id)) {
            state.rightData.push(item)
          }
        })
      } else { // 取消全选
        // 删除右侧数据
        data.forEach(item => {
          if (item.disabled) return
          item.selected = false
          if (state.rightIds.includes(item.dept_id || item.ding_user_id)) {
            const index = state.rightIds.findIndex(id => (id === item.dept_id || id === item.ding_user_id))
            state.rightData.splice(index, 1)
          }
        })
      }
    }

    // 删除全部
    const clearAll = () => {
      confirm({
        message: '清空后，将自动取消所有已勾选人员的勾选状态',
        success: function () {
          // 清空右边数据
          state.rightData.forEach(item => {
            item.disabled = false
            removeItem(item)
          })
          // 清空左边勾选
          selectAll(false, state.leftData)
        },
        cancel: function () {
        }
      })
    }
    // 删除
    const removeItem = (item) => {
      if (item.disabled) return false
      state.rightData = state.rightData.filter(i => i !== item)
      nextTick(() => {
        // 左边去除勾选
        state.leftData.forEach(data => {
          if (item.dept_id && data.dept_id === item.dept_id) {
            data.selected = false
          } else if (item.ding_user_id && data.ding_user_id === item.ding_user_id) {
            data.selected = false
          }
        })
        // 搜索选择列表去除勾选
        const data = state.options.filter(user => user.ding_user_id === item.ding_user_id)
        data && data.length > 0 && (data[0].selected = false)
      })
    }

    // 获取团队信息
    const getTeam = (item) => {
      if (item.selected) return false
      getLeftData(item)
      if (item.level === 1) {
        state.leftBread.splice(1, state.leftBread.length - 1)
      } else if (item.level > state.leftBread.length) {
        state.leftBread.push(item)
      } else if (item.level !== state.leftBread.length) {
        state.leftBread.splice(item.level, state.leftBread.length - item.level)
      }
    }

    // 获取所有用户
    const getUserList = (id) => {
      getTeamUserList({ dept_id: id }).then(res => {
        if (res.code === 0) {
          state.userList.push(...res.data)
          initData()
        }
      })
    }

    // 初始化已选择用户
    const initData = () => {
      state.rightData = []
      state.userList.forEach(item => {
        state.defaultUserIds.includes(item.ding_user_id) && !state.rightIds.includes(item.ding_user_id) && state.rightData.push(item)
        item.disabled = props.disableUserIds.includes(item.ding_user_id) // 不能被删除
      })

      state.leftData.forEach(item => {
        if (item.ding_user_id) { //  默认选中用户
          item.selected = props.defaultAll || state.defaultUserIds.includes(item.ding_user_id)
          item.disabled = props.disableUserIds.includes(item.ding_user_id) // 不能被删除
        } else {
          item.selected = props.defaultAll
        }
        // if (props.defaultAll) { // 全选到右边
        //   state.rightData.push(item)
        // }
      })
    }
    //  根据名字查询
    const getUserByName = (value) => {
      state.filterText = value
      if (value) {
        state.loading = true
        setTimeout(() => {
          state.loading = false
          state.options = state.userList.filter((item) => {
            if (state.rightIds.includes(item.ding_user_id)) {
              item.selected = true
            } else {
              item.selected = false
            }
            if (item.ding_user_id === props.currentUser.user_id) {
              item.disabled = true
            }
            return item.name.includes(state.filterText)
          })
        }, 200)
      } else {
        state.options = []
      }
    }
    const initLeftBread = (data) => {
      state.leftBread = []
      state.teamData = data
      state.teamData.name = '全部'
      state.leftBread.push(state.teamData)
    }

    // 重置数据
    const resetData = () => {
      initLeftBread(state.teamData)
      getLeftData(state.leftBread[0])
      initData()
    }

    return {
      ...toRefs(state),
      getAllTeam,
      clickUser,
      selectItem,
      clearAll,
      removeItem,
      selectAll,
      getLeftData,
      getTeam,
      initData,
      getUserByName,
      resetData
    }
  }
}
</script>

<style lang="less" scoped>
:deep(.el-breadcrumb__inner){
  cursor: pointer;
}
:deep(.el-breadcrumb__inner:hover){
  color: @active-text-color;
}
.transfer-wrapper {
  display: flex;
  justify-content: space-between;
  & > div {
    width: 360px;
    height: 416px;
    padding: 10px 16px;
    background: #fff;
  }
}
.transfer-left{
  margin-right: 8px;
}
.el-breadcrumb{
  margin: 20px 0 10px;
  font-size: 12px;
  line-height: 16px;
}
:deep(.el-breadcrumb__separator){
  margin: 0 2px;
  color: @second-text-color;
}

li {
  height: 40px;
  list-style: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  .el-checkbox,.dep-item{
    padding: 10px 0;
  }
  .el-checkbox{
    margin-right: 15px;
  }
  // 覆盖多选框样式
  :deep(.el-checkbox__inner){
    background: @colorWhite;
    border-radius: 50%;
    border: 1px solid #E0E0E0;
    width: 18px;
    height: 18px;
    &:hover{
      border: 1px solid #E0E0E0;
    }
    &::after{
      border-width: 2px;
      left: 6px;
      top: 2px
    }
  }
  :deep(.el-checkbox__input.is-checked .el-checkbox__inner){
    background-color: @active-text-color;
    border-color: @active-text-color;
  }

  :deep(.el-checkbox__input.is-focus .el-checkbox__inner){
    border-color: @active-text-color;
  }
  .el-select-dropdown  {
    :deep(.el-select-dropdown__item.hover, .el-select-dropdown__item:hover) {
      background-color: transparent;
    }
  }
  .user-info {
    display: flex;
    align-items: center;
    width: 100%;
    .del-blue{
      color: #606266;
    }
  }

  &.rigth-item {
    display: flex;
    justify-content: space-between;
    padding: 8px;
    >span{
      display: flex;
      align-items: center;
      img{
        margin-right: 13px;
      }
      &.disabled{
        cursor: not-allowed;
      }
    }
    .svg {
      cursor: pointer;
    }
  }
}
.dep-item{
  display: flex;
  align-items: center;
  width: 100%;
  &:hover {
    cursor: pointer;
    background: #F1F4F4;
    border-radius: 6px;
  }
  img{
    margin-right: 4px;
  }
}

.search-box {
  position: relative;
  .el-select{
    width: 100%;
    :deep(.el-select__tags) {
      max-width: calc(100% - 44px) !important;
    }
  }
  :deep(.el-input__inner){
    border-radius: 32px;
    border: 1px solid #F1F1F1;
    background: #F6F9FA;
  }
  :deep(.el-select .el-input.is-focus .el-input__inner){
    border: 1px solid #F1F1F1;
    cursor: unset;
  }
  svg{
    position: absolute;
    right: 18px;
    top: 10px;
    cursor: pointer;
  }
}

.clear-box {
  cursor: pointer;
  margin: 0 10px 16px 0;
  text-align: right;
  .hover-icon{
    display: none;
  }
  &:hover{
    .default-icon{
      display: none;
    }
    .hover-icon{
      display: inline-block;
    }
  }
}
</style>
