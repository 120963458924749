import Cookies from 'js-cookie'

export function setSessionStorage (key, value) {
  sessionStorage.setItem(key, value)
}

export function getSessionStorage (key) {
  return sessionStorage.getItem(key)
}

export function setCookies (key, value, params = { expires: 10, domain: '' }) {
  const { expires, domain } = params
  Cookies.set(key, value, { expires: expires || 10, domain: domain })
}

export function getCookies (key) {
  return Cookies.get(key)
}

export function removeCookies (key, domain = '') {
  Cookies.remove(key,
    {
      domain: domain
    })
}

// sessionStorage存储
export function setSession (key, value) {
  return sessionStorage.setItem(key, JSON.stringify(value))
}

export function getSession (key) {
  return JSON.parse(sessionStorage.getItem(key))
}
