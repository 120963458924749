/*
 * @Author: 何琦 heqi@imyfone.cn
 * @Date: 2023-04-12 17:00:52
 * @LastEditors: liujx@imyfone.cn
 * @LastEditTime: 2025-02-14 10:04:45
 * @FilePath: \mfadmind:\hq\mfzj\src\store\modules\prize.js
 * @Description: prizeData
 */
const state = {
  prizeData: {}, // 中奖详情
  prizeList: [], // 奖品列表数据
  selNumber: 1, // 每次抽取人数
  nowGift: {}, // 当前奖品数据
  prizeCondition: {}, // 抽奖弹窗开始抽奖判断条件
  mfOverlay: false // 自定义遮罩层
}

const mutations = {
  setPrizeData (state, payload) { // 设置中奖详情
    if (state.prizeData[payload.prizeId]?.length) { // 已经抽过就push
      return state.prizeData[payload.prizeId].push(...payload.value)
    }
    state.prizeData[payload.prizeId] = payload.value // 没有数据直接存储
    // 减去奖品列表数据
    state.prizeList.filter(i => i.id === payload.prizeId)[0].all_num -= state.prizeData[payload.prizeId].length
  },
  setPrizeList (state, payload) { // 设置奖品列表数据
    state.prizeList = payload
  },
  setSelNumber (state, payload) { // 设置抽取数量
    state.selNumber = payload
  },
  setNowGift (state, payload) { // 当前抽取奖品数据
    state.nowGift = payload
  },

  setPrizeConditions (state, payload) { // 设置抽奖条件
    state.prizeCondition = payload
  },
  setMfOverlay (state, payload) { // 设置遮罩层
    state.mfOverlay = payload
  }
}

const getters = {
  luckyUser (state) {
    const prizeId = state.nowGift.id
    return state.prizeList.find(item => item.id === prizeId).lucky_users
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters
}
